$font-family-base: "Montserrat", sans-serif !important;

label{
  font-weight: 500;
}

button {
  cursor: pointer;
  outline: none;
}

input {
  font-family: $font-family-base;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-base !important;
}
