/* Font Weights */

$f-regular: 400;
$f-medium: 500;
$f-bold: 700;

/* Font Sizes */
$f-xMini: toRem(10);
$f-mini: toRem(12);
$f-xs: toRem(14);
$f-sm: toRem(16);
$f-xmd: toRem(18);
$f-md: toRem(20);
$f-lg: toRem(22);
$f-xlg: toRem(32);
$f-xxl: toRem(36);
