@import 'global/colors';

* {
  font-family: "Montserrat", sans-serif;
}

.material-icons {
  font-family: "Material Icons";
}

.filled_icon{
  fill: $dot-primary !important;
}

mat-sidenav {
  width: 240px;
  padding-top: 0px;
}

mat-icon {
  cursor: pointer;
}

.mat-tab-header {
  margin-bottom: toRem(25);
}

body {
  @apply bg-gray-3 font-normal;
}

.custom-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: toRem(12);
  background-color: $custome-success;
  border-radius: toRem(6);
  padding: toRem(8) toRem(20);
  color: #fff;
}

@layer utilities {
  @variants responsive {
    .h1 {
      @apply font-bold text-5xl leading-14;
    }

    .h2 {
      @apply font-normal text-4xl;
    }

    .h3 {
      @apply font-normal text-2xl;
    }

    .h4 {
      @apply font-normal text-lg leading-6;
    }

    .register-btn {
      @apply bg-secondary w-38 h-12 rounded-md text-white;
    }
  }
}

.mat-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05) !important;
  margin: 15px;
  padding: 0px !important;
  border-radius: 4px;

  .mat-card-content {
    font-size: 1rem;
    line-height: 23px;
    padding: 24px;
  }

  .mat-card-image {
    width: calc(100% + 0px);
    margin: 0 0px 0px 0px;
  }

  .mat-card-header {
    padding: 12px;
    border-radius: 4px 4px 0 0;

    .mat-card-title {
      margin-bottom: 2px;
      font-size: 20px;
    }

    .mat-card-subtitle,
    .mat-card-subtitle:not(:first-child) {
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 14px;
    }

    &.text-white {
      .mat-card-title {
        color: $c-white;
      }

      .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .mat-card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .mat-card-subtitle {
    margin-bottom: 20px;
  }

  .mat-card-actions {
    margin: 0px !important;
  }
}

.user-profile {
  .profile-img {
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }

  .profile-text {
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    >a {
      color: $c-white;
      width: 100%;
      padding: 6px 30px;
      background: $c-prominent;
      display: block;
      white-space: nowrap;
      box-sizing: border-box;
      text-align: center;
    }
  }
}

.sidebar-closed #snav {
  &.mat-sidenav~.mat-drawer-content {
    margin-#{$left}: 0px !important;
  }
}

.sidebar {
  a {
    cursor: pointer;
  }

  a:hover,
  .mat-icon:hover {
    color: #004aad !important;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  bottom: 0;
  #{$left}: 0;
  #{$right}: 0;
}

html .page-wrapper {
  height: calc(100vh - 64px);

  .page-content {
    padding: 15px;
  }
}

.flex1 {
  flex: 1;
}

mat-toolbar {
  &.topbar {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
  }

  .navbar-header {
    text-align: center;

    .navbar-brand {
      display: flex;
      line-height: 18px;
      align-items: center;

      .dark-logo {
        display: none;
      }

      b {
        margin-#{$right}: 5px;
      }
    }
  }

  .profile-pic {
    width: toRem(30);
    height: toRem(30);
    border: toRem(1) solid $c-white;
    border-radius: 100%;
  }

  .logo {
    width: 120px;
  }
}

.profile-pic {
  width: toRem(30);
  height: toRem(30);
  border-radius: 50%;
  object-fit: contain;
  border: toRem(1) solid $c-white;
}

.topbar {
  background: $c-white;
  position: relative;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);

  z-index: 2;
  height: toRem(75);

  &__logo {
    vertical-align: middle;
  }

  &__toggle {
    background-color: $c-secondary;
    color: $c-white;
    padding: 5px;
    border-radius: 2px;
    margin-#{$left}: toRem(93);

    &_rotated {
      transform: rotate(-180deg);
      transition: transform 150ms ease;
    }

    @media only screen and (max-width: 600px) {
      margin-#{$left}: toRem(10);
    }
  }

  &__auxiliary {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-#{$right}: toRem(10);
  }

  &__user {
    font-size: $f-xs;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 toRem(10);
    text-justify: #{$right};
    cursor: pointer;

    &-name {
      margin: 0;
      display: block;
      color: $c-primary;
      font-size: $f-xs;
    }

    &-role {
      position: absolute;
      top: toRem(28);
      #{$right}: toRem(15);
      color: $c-gray-4;
      padding-top: 0;
      font-size: $f-mini;
      margin: 0;
    }

    &-pic {
      display: flex;
      align-items: center;

      .mat-icon-button {
        width: toRem(30);
      }
    }
  }

  &__profile-menu {
    button:not(:first-child) {
      border-top: 1px solid $c-gray-2;
    }
  }

  &__icon {
    color: $c-secondary !important;
    margin-inline-end: toRem(5);
    font-size: toRem(18);
    vertical-align: middle;
  }
}

.sidenav-container {
  flex: 1 0 auto;
}

.mat-form-field {
  width: 100%;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $c-secondary;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: $c-secondary;
}

.alert-span {
  color: $c-dark-red;
}

.success-span {
  color: $c-prominent;
}

.downloadSpinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  #{$left}: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-#{$left}: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: downloadSpinner 0.8s linear infinite;
}

@keyframes downloadSpinner {
  to {
    transform: rotate(360deg);
  }
}

.diection-ltr {
  input {
    direction: ltr;
    text-align: initial !important;
  }
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      background-color: $c-white;
      color: $c-primary;
      box-shadow: 0px 4px 8px rgba(130, 136, 148, 0.16);
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

.mat-paginator-range-label {
  direction: ltr;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 40px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  min-height: 40px !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
}

.mat-mdc-text-field-wrapper {
  margin-top: 4px;
  background-color: #f9fafb;
}

.mat-mdc-select-trigger {
  padding: 8px 1rem;
  height: 100% !important;
}

.mat-stepper-horizontal {
  background: transparent;

  .mat-horizontal-stepper-header {
    display: flex;
    flex-direction: column;
    padding: 0.5rem !important;
    //  margin: 0 1rem;
    // width: 140px;
    height: auto !important;
    border-radius: 5px;

    &-container {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 1rem 0;
      margin-bottom: 1.5rem;
    }

    .mat-stepper-horizontal-line {
      margin: 0;
    }

    .mat-step-icon {
      height: 35px;
      width: 35px;
      margin-bottom: 0.5rem;
      margin-#{$right}: 0 !important;
    }
  }

  .mat-horizontal-content-container {
    padding: toRem(17) 0;
    overflow: unset;
  }
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding-#{$right}: 0.5rem;
  padding-#{$left}: 0.5rem;
}

.side_bar__item_menu__header {
  text-align: $left;
}

.mdc-notched-outline {
  min-height: 30px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #dee2e6;
}

.mat-mdc-paginator-page-size-select {
  width: 100px !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.header-card {
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 3rem;
  margin-top: 1.8rem;
  border-radius: 0.75rem;
  position: relative;
  box-shadow: 0rem 0.0625rem 0.625rem -0.0625rem #eee;

  .card-header {
    position: relative;
    margin-top: -4.75rem;
    background-color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem 0.625rem 0 0;
    page-break-inside: avoid;
    width: max-content;

    h3 {
      font-size: 1.125rem;
      font-weight: 600;
    }

    p {
      margin: 0 !important;
    }
  }

  .top_bar {
    background-color: $c-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: toRem(12) toRem(12) 0 0;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: toRem(24);
  }
}

.title,
.sub_title {
  color: $custome-success;
  font-size: toRem(24);
  font-weight: 600;
  margin-bottom: 1rem;
}

.sub_title {
  font-size: toRem(20);

}

.custom-grid {
  column-count: 2;
  column-gap: 1.5rem;

  .item {
    break-inside: avoid;
    margin-bottom: 3rem;
  }

  @media (max-width: 991px) {
    column-count: 1;
  }
}

.custom-flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // gap: 1.5rem;

  .item {
    break-inside: avoid;
    margin-bottom: 3rem;
    width: calc(50% - 0.75rem);

    >.header-card {
      height: 100%;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow-y: unset !important;
  border-radius: 0.75rem !important;
}

.modal-wrapper {
  padding: 1.5rem;
  padding-top: 3rem;
  border-radius: 0.75rem !important;
  position: relative;
  box-shadow: 0rem 0.0625rem 0.625rem -0.0625rem #eee;

  .modal-header {
    margin-top: -4.75rem;
    color: #004aad;
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem 0.625rem 0 0;
    font-size: 1.125rem;
    font-weight: 600;
    page-break-inside: avoid;

    .header {
      padding: 0 !important;

      h1 {
        padding: 0 !important;
      }
    }
  }
}

.mdc-dialog__actions {
  padding: 0 !important;
}

.mdc-dialog__title::before {
  content: unset !important;
}

.mdc-dialog__content> :first-child {
  max-height: 60vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &:hover {
      background: rgba(0, 0, 0, 0.25);
    }
  }
}


.search-icon {
  top: toRem(12);
  #{$right}: toRem(10);

}

.mat-mdc-form-field-icon-suffix {
  span {
    padding: 0 .5rem;
  }
}
.cpo {
  .config-list,
  .main-list {
    border: dashed 2px #ccc;
    min-height: 5rem;
    padding: .5rem;
    margin: 0.5rem 0;
  }

  .main-list {
    min-height: 10rem;
  }

  .cdk-drag-preview {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .example-box:last-child {
    border: none;
  }

  .config-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag-placeholder {
    background-color: #ccc;
    border: 2px dashed #999;
    opacity: 0.3;
    height: 4rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .s-container {
    min-height: 100vh;
    border: 2px solid #ddd;
    box-shadow: 0rem 0.0625rem 0.625rem -0.0625rem #eee;
    overflow: unset;
  }

  .sidenav {
    min-height: 100vh;
    width: 28%;
    background-color: #f7f8ff;

    .card {
      cursor: move;

      .card-body {
        padding: 0.3rem;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }

  .mat-drawer-content {
    overflow: unset;
  }

  .sidenav-content {
    height: 100%;
    padding: 0.5rem;
    border-right: 2px solid #ddd;
  }

  .mat-expansion-indicator::after {
    border-color: #0000008a;
  }

  .mat-expansion-panel {
    all: unset;
  }

  .mat-expansion-panel-body {
    padding: 0.5rem;
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded {
    height: 43px;
    background: rgb(245, 245, 245) !important;
    box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    padding: 0 .5rem;
    border-bottom: 1px solid #ddd;

    &:hover,
    &:focus {
      background: #fff;
    }
  }

  .mat-expansion-panel-header-title {
    font-weight: 600;
    font-size: 15px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    line-height: 0;
  }

  .dg-form,
  .dg-table {
    position: relative;
    display: inline-block;

    .delete {
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, .5);
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .dg-form {
    .delete {
      position: absolute;
      top: 8px;
      right: 8px;
      transition: all .2s ease-in-out;

      &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, .5);
      }

      svg {
        opacity: 0;
      }
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  .dg-confg {
    .card-body {
      padding: .3rem;
      display: flex;
      align-items: center;

      .material-icons {
        font-size: 19px;
        height: 20px;
        width: 20px;
      }
    }
  }
}
