@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* Global Settings */
@import 'global/colors';
@import 'global/mixins';
@import 'global/functions';
@import 'global/typography';
@import 'global/generics';

/* Base */

/* Modules */
@import 'modules/animations';
@import 'modules/spinner';
@import "tailwindcss/utilities";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import 'base';
@import './components/btn';
@import './components/select';



$left: left;
$right: right;

/* Primeng Framework */
@import "../../../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../../../node_modules/primeng/resources/primeng.css";
@import "../../../node_modules/primeicons/primeicons.css";

// BPMN STYLES
@import "../../../node_modules/bpmn-js/dist/assets/bpmn-js.css";
@import "../../../node_modules/bpmn-js/dist/assets/diagram-js.css";
@import "../../../node_modules/bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
@import "../../../node_modules/bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
@import "../../../node_modules/bpmn-js-properties-panel/dist/assets/properties-panel.css";
@import "../../../node_modules/bpmn-js-properties-panel/dist/assets/element-templates.css";

// DMN STYLES
@import "../../../node_modules/dmn-js/dist/assets/diagram-js.css";
@import "../../../node_modules/dmn-js/dist/assets/dmn-js-shared.css";
@import "../../../node_modules/dmn-js/dist/assets/dmn-js-drd.css";
@import "../../../node_modules/dmn-js/dist/assets/dmn-js-decision-table.css";
@import "../../../node_modules/dmn-js/dist/assets/dmn-js-decision-table-controls.css";
@import "../../../node_modules/dmn-js/dist/assets/dmn-js-literal-expression.css";
@import "../../../node_modules/dmn-js/dist/assets/dmn-font/css/dmn.css";

@import 'app';


.orgchart.myChart {
    background-image: linear-gradient(90deg, rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%)!important;
  }

  .orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: #215a88;
    color: #fff;
    height: 65px;
    padding: 2px;
  }

  .orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    color: #215a88;
    background-color:#fff;
    height: 65px;
    padding: 2px;
    border: 1px solid #215a88;
  }

  .orgchart.myChart > orgchart-node > .oc-node::after {
    background-color: rgba(33, 90, 136, 0.8) !important;
  }

  .orgchart.myChart .oc-group:not(:last-child)::before {
    border-color: rgba(33, 90, 136, 0.8)!important;
  }

  .orgchart.myChart .oc-group > orgchart-node::before {
    border-color: rgba(33, 90, 136, 0.8)!important;
  }

  .orgchart.myChart orgchart-node:not(:last-child)::after {
    border-color: rgba(33, 90, 136, 0.8)!important;
  }

  #oc-1 .fullname {
    color: blue;
  }
