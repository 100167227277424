.dot_btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: toRem(48);
  color: $c-gray;
  background: #fff;
  border: toRem(1) solid $c-gray;
  font-size: toRem(18);
  font-weight: 500;
  outline: 0;
  padding: toRem(10) toRem(20);
  border-radius: toRem(5);
  transition: all 0.2s ease-out;
  gap: toRem(10);

  &:hover{
      color: #fff;
      background: $c-gray;
  }

  // COLORs
  &_primary{
    background: #fff;
    color: $dot-primary;
    border: toRem(1) solid $dot-primary;

    &:hover{
        color: #fff;
        background: $dot-primary;
    }
  }
  &_secondary{
    background: #fff;
    color: $c-secondary;
    border: toRem(1) solid $c-secondary;

    &:hover{
        color: #fff;
        background: $c-secondary;
    }
  }
  &_success{
    background: #fff;
    color: $c-success;
    border: toRem(1) solid $c-success;

    &:hover{
        color: #fff;
        background: $c-success;
    }
  }
  &_warning{
    background: #fff;
    color: $c-warning;
    border: toRem(1) solid $c-warning;

    &:hover{
        color: #fff;
        background: $c-warning;
    }
  }
  &_light{
    background: #fff;
    color: $c-white;
    border: toRem(1) solid $c-white;

    &:hover,
    &:active,
    &:focus{
        color: $c-dark;
        background: $c-white;
    }
  }
  &_dark{
    background: #fff;
    color: $c-dark;
    border: toRem(1) solid $c-dark;

    &:hover,
    &:active,
    &:focus{
        color: #fff;
        background: $c-dark;
    }
  }
  &_danger{
    background: #fff;
    color: $c-dark-red;
    border: toRem(1) solid $c-dark-red;

    &:hover,
    &:active,
    &:focus{
        color: #fff;
        background: $c-dark-red;
    }
  }

  // SIZE
  &_xsm{
    padding: toRem(4) toRem(8);
    min-height: toRem(24);
    font-size: toRem(12);
    font-weight: 500;
  }
  &_sm{
    padding: toRem(8) toRem(16);
    min-height: toRem(32);
    font-size: toRem(16);
    font-weight: 500;
  }
  &_md{
    padding: toRem(12) toRem(24);
    min-height: toRem(48);
    font-size: toRem(18);
    font-weight: 500;
  }
  &_lg{
    padding: toRem(16) toRem(32);
    min-height: toRem(64);
    font-size: toRem(24);
    font-weight: 500;
  }


  &:disabled {
    cursor: not-allowed;
    color: $c-disabled;
    border: toRem(1) solid $c-disabled;

    &:hover,
    &:active,
    &:focus{
        color: $c-disabled;
        background: transparent;
    }
  }
}
