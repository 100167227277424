/** main colors */
$c-primary: rgba(0, 18, 44, 1);
$c-secondary: rgba(2,132,199,1);
$c-prominent: rgba(0, 127, 89, 1);

/** main colors Hover */
$c-secondary-hover: rgba(2,132,199,1);
$c-prominent-hover: rgba(39, 174, 96, 1);
/** Danger */
$c-dark-red: rgba(192, 57, 43, 1); // ERRORS
$c-danger: rgba(235, 87, 87, 1);
$c-danger-hover: rgba(235, 87, 87, 1);
$c-danger-2: rgba(235, 87, 87, 0.12);
$c-danger-2-hover: rgba(235, 87, 87, 0.24);

/* warning */
$c-warning: rgba(255, 190, 79, 1);
$c-warning-hover: rgba(255, 190, 79, 1);
$c-warning-2: rgba(255, 190, 79, 0.16);
$c-warning-2-hover: rgba(255, 190, 79, 0.24);

/* success */
$c-success: $c-prominent;
$c-success-hover: $c-prominent-hover;
$c-success-2: rgba(39, 174, 96, 0.12);
$c-success-2-hover: rgba(39, 174, 96, 0.24);

/* white */
$c-white: rgba(255, 255, 255, 1);

/* gray */
$c-gray: rgba(189, 189, 189, 1);
$c-gray-2: rgba(230, 230, 235, 1);
$c-gray-3: rgba(244, 245, 247, 1);
$c-gray-4: rgba(146, 146, 146, 1);

/* black */
$c-black: rgba(0, 0, 0, 1);
$c-black-hover: rgba(0, 0, 0, 0.7);
$c-dark: rgba(33, 37, 41, 1);
/* green */
$c-green: rgb(43, 158, 89);

/* Hover */
$c-default-hover: rgba(39, 39, 41, 0.12);

/* disabled */
$c-disabled: rgba(0, 0, 0, 0.12);

/* elevation */
$c-elevation: rgba(130, 136, 148, 0.16);

/* Custome */
$custome-success: #004aad;
$dot-primary: #004aad;
