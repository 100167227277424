.ng-select.ng-select-single .ng-select-container {
    height: 2.5rem;
    margin-top: .25rem!important;
    line-height: toRem(29);
    background: rgb(249 250 251);
}

.ng-dropdown-panel.ng-select-top {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

