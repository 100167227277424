@keyframes spinner-animation {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}
@keyframes hideToolTip {
  0% {
    display: none;
    opacity: 0;
  }
  5% {
    display: block;
    opacity: 1;
  }
  95% {
    display: block;
    opacity: 1;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
